<div class="cl-list">
  <div [ngClass]="formFieldName">
    <cl-autocomplete-component
      id="itemInput"
      #itemInput
      (inputchange)="onInputChange($event)"
      (noMatchesCallback)="listNoMatchesCallback($event)"
      (onSelect)="add($event)"
      [allowFreeText]="allowFreeText"
      [allowMultipleEntry]="allowMultipleEntry"
      [allowMultipleLines]="allowMultipleLines"
      [data]="_autoCompleteItems"
      [disabled]="disabled || showMaxSizeHint"
      [errorValidations]="listErrorValidations"
      [functionIndicator]="'adder'"
      [label]="formFieldLabel | translate"
      [minLength]="1"
      [matchAttributes]="matchAttributes"
      [matchesLimit]="6"
      [microText]="getMicroText()"
      [placeholder]="placeholder"
      [retainSelection]="true"></cl-autocomplete-component>
  </div>
  <table
    [hidden]="!listItems || listItems.length === 0"
    class="spot-data-table spot-data-table--small-spacing list-component"
    style="margin-top: 0">
    <tbody #childItems>
      <tr *ngFor="let item of listItems" [ngClass]="getListItemsClass(item)" luxLayout="row">
        <td class="list-item-data">
          <div>
            <p
              *ngIf="!allowEdit"
              [attr.contenteditable]="allowEdit"
              (input)="onInput(item, $event)"
              (focus)="edit(item, $event)"
              (blur)="blurItem(item, $event)"
              [ngClass]="getEditableClass(item)">
              <!--suppress TypeScriptUnresolvedReference -->
              <span *ngIf="item['value'] && item['value'].shortCode">
                <!--suppress TypeScriptUnresolvedReference -->
                <span class="short-code-selected">{{ searchChar }}{{ item['value'].shortCode }}</span
                >&nbsp;- </span
              >{{ item.info.initialText }}
            </p>
            <p
              *ngIf="allowEdit"
              [attr.contenteditable]="allowEdit"
              (input)="onInput(item, $event)"
              (focus)="edit(item, $event)"
              (blur)="blurItem(item, $event)"
              [ngClass]="getEditableClass(item)"
              [textContent]="item['info'].initialText"></p>
          </div>
        </td>
        <td [style.position]="'relative'" luxFlex="45px" luxLayoutAlign="space-around center">
          <div *ngIf="item.info.editing && maxTextSize" class="cl-list-counter-wrapper">
            <div class="cl-list-counter">{{ item.info.text.length }} / {{ maxTextSize }}</div>
          </div>

          <a
            href="javascript:void(0)"
            *ngIf="item.info.editing && item.info.text !== item.info.initialText"
            class="focusable list-action cancel-list-item"
            (focus)="focusItem(item, $event)"
            (blur)="blurItem(item, $event)"
            (click)="cancelEdit(item, $event)"
            (mousedown)="cancelEdit(item, $event)">
            <svg class="spot-icon" aria-labelledby="title">
              <title>{{ 'BUTTONS.CANCEL' | translate }}</title>
              <use xlink:href="/assets/icons/spot_icons.svg#cancel"></use>
            </svg>
          </a>
        </td>
        <td *ngIf="processingDelete" luxFlex="30px" luxLayoutAlign="space-around center">
          <svg class="spot-icon spot-loading-spinner" aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
          </svg>
        </td>
        <td *ngIf="!processingDelete" luxFlex="30px" luxLayoutAlign="space-around start">
          <!-- Cypress was being really tough on me here.
          Angular handles click and keydown.enter the same way,
          and using both on the same anchor element shouldn't be necessary,
          but I had to use both click and keydown.enter bindings here
          to get the acceptance tests working.-->
          <a
            href="javascript:void(0)"
            class="focusable list-action delete-list-item"
            (click)="delete(item, $event)"
            (keydown.enter)="delete(item, $event)"
            (blur)="blurItem(item, $event)">
            <svg class="spot-icon" aria-labelledby="title">
              <title>{{ 'BUTTONS.DELETE' | translate }}</title>
              <use xlink:href="/assets/icons/spot_icons.svg#delete"></use>
            </svg>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
