export class StringUtil {
  static readonly SPECIAL: string[] = `\\/"';:][}{+=-_.>,<?\`~!@#$%^&*()|`.split(/(.)/).filter((v) => v);

  static NUM_TEST = /^\-?[0-9]+([\.][0-9]+)*$/;
  static ALPHA_TEST = /^[a-zA-Z]*$/;
  static WHOLE_NUM_TEST = /^\-?[0-9]+$/;
  static readonly HAS_SPECIAL_TEST = new RegExp(`[\\${StringUtil.SPECIAL.join('\\')}]{${1}}`);
  static EMAIL_TEST = /^.+\@.+\..+$/;
  static PHONE_TEST = /^\+?[0-9\s\-\.]*(\([0-9]*([\s\-\.]*[0-9]*)?\)|([0-9]*([\s\-\.]*[0-9])?))*$/;

  static join(strings, ...parts): string {
    if (typeof strings === 'string') {
      return strings;
    }
    if (strings === null || strings === undefined) {
      return '';
    }

    const pln = parts.length;
    let result = '';

    for (let i = 0, ln = strings.length; i < ln; i++) {
      result += strings[i] + (i < pln ? parts[i] : '');
    }
    return result;
  }
  static isNumber(strings, ...parts): boolean {
    return this.NUM_TEST.test(StringUtil.join(strings, ...parts));
  }
  static isWholeNumber(strings, ...parts): boolean {
    return this.WHOLE_NUM_TEST.test(StringUtil.join(strings, ...parts));
  }
  static hasSpecial(strings, ...parts): boolean {
    return this.HAS_SPECIAL_TEST.test(StringUtil.join(strings, ...parts));
  }
  static isAlpha(strings, ...parts): boolean {
    return this.ALPHA_TEST.test(StringUtil.join(strings, ...parts));
  }
  static toLower(strings, trim = true): string {
    strings = !strings && typeof strings !== 'number' ? '' : strings;
    const result = strings.toString()?.toLowerCase();
    return trim ? result.trim() : result;
  }
  static isEmail(strings, ...parts): boolean {
    return this.EMAIL_TEST.test(StringUtil.join(strings, ...parts));
  }
  static isFax(strings, ...parts): boolean {
    strings = StringUtil.join(strings, ...parts);
    const test = strings.match(/[0-9]/g);
    return test && test.length === 10 && this.PHONE_TEST.test(strings);
  }
  static trim(strings, ...parts): string {
    return StringUtil.join(strings, ...parts).trim();
  }
  static removeNonNumbers(str: string): string {
    if (str) {
      return str.replace(/\D/g, '');
    } else {
      return str;
    }
  }

  // Pads 1-digit string with leading zero
  static padDigits(value): string {
    value = value + '';
    if (value.length === 1 && StringUtil.isWholeNumber(value) && value !== '0') {
      return `0${value}`;
    } else if (StringUtil.isWholeNumber(value) && value !== '0') {
      return value;
    }
    return null;
  }
}
